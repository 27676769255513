export const HEIC_MIME_TYPE = 'image/heic' as const;

export const AUDIO_MIME_TYPES = [
    "audio/mpeg",
    "audio/mp4",
    "audio/x-m4a",
    "audio/m4a",
    "audio/x-wav",
    "audio/x-ms-wma",
    "audio/x-ms-wax",
    "audio/x-aiff",
    "audio/x-flac",
    "audio/x-matroska",
    "audio/x-mpegurl",
    "audio/x-ms-asf",
    "audio/x-ms-wmv",
    "audio/x-ms-wvx",
    "audio/x-ms-wm",
    "audio/x-pn-realaudio",
    "audio/x-pn-realaudio-plugin",
    "audio/x-realaudio",
    "audio/x-pn-wav",
    "audio/x-pn-windows-acm",
    "audio/x-pn-windows-pcm",
    "audio/x-pn-realaudio-encrypted",
    "audio/x-pn-realaudio-secure",
    "audio/x-pn-realaudio-secure-2",
    "audio/x-pn-realaudio-plugin"
] as const;

export const VIDEO_MIME_TYPES = [
    "video/mp4",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/quicktime",
    "video/x-flv",
    "video/x-matroska",
    "video/x-m4v",
    "video/mpeg",
] as const;

export const UN_PREVIEWABLE_MIME_TYPES = [
    ...HEIC_MIME_TYPE,
    ...AUDIO_MIME_TYPES,
    ...VIDEO_MIME_TYPES
] as const;

export const IMAGE_MIME_TYPES = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    'image/tiff',
    'image/tif',
    "image/webp",
    "image/bmp"
] as const;

export const PDF_MIME_TYPES = [
    "application/pdf"
] as const;

export const TXT_MIME_TYPES = [
    "text/plain"
] as const;



export const PRESENTATION_MIME_TYPES = [
    "application/mspowerpoint",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.template",
    "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    "application/vnd.ms-powerpoint.template.macroEnabled.12",
] as const;

export const DOC_MIME_TYPES = [
    "application/msword",
    "application/vnd.ms-word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.ms-word.document.macroEnabled.12",
    "application/vnd.ms-word.template.macroEnabled.12",
] as const;


export const XSL_MIME_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.ms-excel.template.macroEnabled.12",
    "application/vnd.ms-excel.addin.macroEnabled.12",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
] as const;

export const ZIP_MIME_TYPES = [
    "application/zip",
    "application/octet-stream",
    "application/x-zip-compressed",
    "multipart/x-zip"
] as const;

export const OTHER_MIME_TYPES = [
    ...PRESENTATION_MIME_TYPES,
    ...DOC_MIME_TYPES,
    ...XSL_MIME_TYPES,
    // "image/tiff",
    "application/msexcel",

] as const;

export const ALLOWED_UPLOAD_FILE_TYPES = [
    HEIC_MIME_TYPE,
    ...ZIP_MIME_TYPES,
    ...IMAGE_MIME_TYPES,
    ...PDF_MIME_TYPES,
    ...OTHER_MIME_TYPES,
    ...VIDEO_MIME_TYPES,
    ...AUDIO_MIME_TYPES,
    ...XSL_MIME_TYPES,
    ...PRESENTATION_MIME_TYPES,
    ...DOC_MIME_TYPES,
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
] as const;