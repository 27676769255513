import dynamic from 'next/dynamic';
import React from 'react';
import { useFetchUserPreferences } from 'src/hooks/use-fetch-user-prefrences';

import { SplashScreen } from '../splash-screen';

const PrivacyCookieConsentDialog = dynamic(() => import('./privacy-cookie-consent-dialog.component').then((mod) => mod.PrivacyCookieConsentDialog));


export const PrivacyCookieConsent: React.FC<React.PropsWithChildren<object>> = (props) => {
  const { loading, agreedToTOS } = useFetchUserPreferences();

  if (loading) {
    return <SplashScreen />
  }

  if (agreedToTOS) {
    return props.children;
  }

  return (<PrivacyCookieConsentDialog />);
}
