import { AssignAvatarProps } from "src/components/form-elements/assign-avatar/assign-avatar.types";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";

export const getAssignAvatarMode = (sharedInfo: FormElementV2ResponseDtoExtended['sharedInfo']): AssignAvatarProps["mode"] => {
    if (!sharedInfo) {
        return "DEFAULT";
    }
    // filter out LINK permission
    const sharedInfoWithoutLink = sharedInfo
        .filter((info) => !info.permissions.includes('LINK'));
    // get all users roles in sharedInfoWithoutLink
    const defaultRoles = sharedInfoWithoutLink
        .map((info) => info.sharedWithUser.loggedRoleGroup)
        // filter out undefined roles
        .filter((role) => !!role);

    if (defaultRoles.length === 1) {
        if (defaultRoles[0] === "BORROWER") {
            return "BORROWER";
        } else if (defaultRoles[0] === "LENDER") {
            return "LENDER";
        } else if (defaultRoles[0] === "CONTACT") {
            return "CONTACT";
        }
    }

    const hasBorrower = defaultRoles.some(role => role === "BORROWER");
    const hasLender = defaultRoles.some(role => role === "LENDER");
    const hasContact = defaultRoles.some(role => role === "CONTACT");

    // if there are multiple roles, check if there is a mix of roles
    // if we have a mix of two roles, return MIXED
    if (hasBorrower && hasLender) {
        return "MIXED";
    } else if (hasBorrower && hasContact) {
        return "MIXED";
    } else if (hasLender && hasContact) {
        return "MIXED";
    } else if (hasBorrower) {
        return "BORROWERS";
    } else if (hasLender) {
        return "LENDERS";
    } else if (hasContact) {
        return "CONTACTS";
    }

    return "DEFAULT"
};