import { FormElementV2ResponseDtoExtended } from "src/types/formelement";

// get next and previous element of a package element
export const getPackageElementPreviousNext = (elements: FormElementV2ResponseDtoExtended[], activeElement: FormElementV2ResponseDtoExtended, { onlyWithAnswers = false, onlySameFolder = false }: { onlyWithAnswers: boolean, onlySameFolder: boolean }): {
    nextElement: FormElementV2ResponseDtoExtended | null;
    previousElement: FormElementV2ResponseDtoExtended | null;
} => {

    if (!activeElement) return ({ nextElement: null, previousElement: null });

    const answeredElementsInSameParent = elements.filter((el) => (el.answer || !onlyWithAnswers) && (el.parentId === activeElement.parentId || !onlySameFolder) && el.storageType === "FILE");
    const currentElementIndex = answeredElementsInSameParent?.findIndex((el) => el.id === activeElement.id);
    const nextElement = answeredElementsInSameParent?.[currentElementIndex + 1] ?? null;
    const previousElement = answeredElementsInSameParent?.[currentElementIndex - 1] ?? null;
    return ({
        nextElement,
        previousElement
    });
};