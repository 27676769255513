import ActionAlertDialog from 'components/dist/molecules/ActionAlertDialog';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { PrivacyCookieConsent } from 'src/components/privacy-cookie-consent';
import { GuestRoutes, Route } from 'src/constants/ui';
import { useAuth } from 'src/hooks/use-auth';
import { useInterval } from 'usehooks-ts';


interface AuthGuardProps {
    children: JSX.Element;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props;
    const auth = useAuth();

    const { query, isReady, asPath, push } = useRouter();
    const [checked, setChecked] = useState(false);

    const onConfirmSessionTimeout = () => {
        auth.refetchCurrentUser();
    };

    useInterval(() => {
        if (auth.isAuthenticated) {
            auth.authenticate();
        }
    }, 60000);

    useEffect(() => {
        if (!isReady || !auth.isInitialized) {
            return;
        }
        const asPathWithoutParams = String(asPath).split('?')[0];

        if (!auth.isAuthenticated && !(GuestRoutes as string[]).includes(asPathWithoutParams)) {
            push({
                pathname: Route.LOGIN,
                query: { ...query, returnUrl: encodeURIComponent(asPath) }
            });
            setChecked(false);
        } else if ((GuestRoutes as string[]).includes(asPathWithoutParams)) {
            push({
                pathname: asPathWithoutParams,
                query
            });
        } else {
            setChecked(true);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isReady, auth.isInitialized, auth.isAuthenticated, push, asPath, query]
    );

    if (!checked || !auth.isAuthenticated || !isReady) {
        return null;
    }
    // If got here, it means that the redirect did not occur, and that tells us that the user is
    // authenticated / authorized.

    const didLoginWithConfirmationCode = auth.user.loginLevel === 'CONFIRMATION_CODE'
    return <PrivacyCookieConsent>
        <>
            {children}
            <ActionAlertDialog
                noCancel
                onOpenChange={() => { }}
                open={auth.isSessionTimedOut}
                title="Session Timeout"
                confirmButtonText={didLoginWithConfirmationCode ? 'Verify Email' : 'Log in'}
                message={`You have been inactive for some time. To protect your account, you have been logged out. ${didLoginWithConfirmationCode ? "Verify Email" : "Log in"} to continue.`}
                onConfirm={onConfirmSessionTimeout}
            />
        </>
    </PrivacyCookieConsent>;
};
