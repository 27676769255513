import Icon from "components/dist/atoms/Icon"
import { UserAvatar } from "src/components/user/user-avatar"
import { getInitials } from "src/utils/get-initials"
import { getUserDisplayName } from "src/utils/user/get-user-display-name"

import { AssignAvatarProps } from "./assign-avatar.types"

export const AssignAvatar = ({
    mode = "DEFAULT",
    ...props
}: AssignAvatarProps) => {
    if (["BORROWER", "LENDER", "CONTACT"].includes(mode) && props.user) {
        return <UserAvatar
            size="xs"
            userId={props.user.id}
            role={props.role}
            avatarDocId={props.user.avatarDocId}
        >
            {getInitials(getUserDisplayName(props.user))}
        </UserAvatar>
    }

    if (["LENDERS", "BORROWERS", "MIXED", "CONTACTS"].includes(mode)) {
        return <Icon
            name="GroupPeople"
            className={`
            ${mode === "LENDERS" ? "bg-blue-v1800 border-blue-v1800 text-white" : ""}
            ${mode === "CONTACTS" ? "bg-yellow-76 border-yellow-60 text-black-primary" : ""}
            ${mode === "BORROWERS" ? "bg-yellow-60 border-yellow-60 text-black-primary" : ""}
            ${mode === "MIXED" ? "bg-white border-black-primary text-black-primary" : ""}
            border rounded-full p-1`}
            width={24}
            height={24}
            strokeWidth={1.5} />
    }

    return <Icon
        name="AddUser"
        width={20}
        height={20}
        strokeWidth={1.5} />
}
