// determine a mime type from a filename

export const getMimeTypeFromFilename = (filename: string) => {
    if (!filename) return '';

    const extension = filename.split('.').pop();
    if (extension) {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return 'application/pdf';
            case 'doc':
            case 'dotx':
            case 'docx':
                return 'application/msword';
            case 'xls':
            case 'xlsm':
            case 'xlsx':
                return 'application/vnd.ms-excel';
            case 'ppt':
            case 'pptx':
                return 'application/vnd.ms-powerpoint';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            case 'bmp':
                return 'image/bmp';
            case 'txt':
                return 'text/plain';
            case 'html':
                return 'text/html';
            case 'zip':
                return 'application/zip';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'heic':
                return 'image/heic';
            case 'mov':
                return 'video/quicktime';
            case 'mp4':
                return 'video/mp4';
            case 'mp3':
                return 'audio/mpeg';
            case 'm4a':
                return 'audio/x-m4a';
            case 'wav':
                return 'audio/wav';
            case 'ogg':
                return 'audio/ogg';
            case 'webm':
                return 'audio/webm';
            case 'avi':
                return 'video/x-msvideo';
            case 'wmv':
                return 'video/x-ms-wmv';
            case 'mkv':
                return 'video/x-matroska';
            case 'flv':
                return 'video/x-flv';
            case '3gp':
                return 'video/3gpp';
            case '3g2':
                return 'video/3gpp2';
            case 'mts':
                return 'video/MP2T';
            case 'm3u':
            default:
                return 'unknown';
        }
    }
}
