import Badge from "components/dist/atoms/Badge"
import Text from "components/dist/atoms/Text"
import { Fragment } from "react";
import { LoanDto, PackageInfoSharingResponseDto } from "src/backend";
import { UserListItem } from "src/components/v2/user-list-item";

import { useSharedWithTeams } from "./shared-with-team.state";

interface SharedWithTeamsProps {
    sharedInfo: PackageInfoSharingResponseDto[];
    loanRoles: LoanDto['loanRoles'];
    loanEntities: LoanDto['loanEntities'];
}

export const SharedWithTeams = (props: SharedWithTeamsProps) => {
    const state = useSharedWithTeams(props);
    return <div className='w-96'>
        <Text className='px-4 pb-1 pt-3 border-b' size="sm">Assigned To</Text>
        <div className='p-4 flex gap-7 flex-col overflow-hidden'>
            {state.teams.map(team => <Fragment key={team.name}>
                <Text as="div" weight="medium" size='sm' className='flex gap-2'>
                    <Badge variant='secondary' className='w-5 h-5 grow-0 inline-flex items-center justify-center'>
                        {team.assignedList.length}
                    </Badge>  {team.name}
                </Text>
                {team.assignedList.map(assigned => (<UserListItem
                    key={assigned.user.id}
                    loanEntities={props.loanEntities}
                    loanRoles={props.loanRoles}
                    user={{
                        ...assigned.user,
                        role: assigned.role,
                        assignedType: assigned.type
                    }}
                    role={assigned.role}
                />))}
            </Fragment>)}
        </div>
    </div>
};