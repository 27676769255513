import { TeamMemberSelectListAssignedListItem } from "src/components/v2/team-member-select-list/team-member-select-list.types";

import { isRoleABorrower } from "../user/is-role-a-borrower";

export const getElementSharedInfoTeams = (assignedList: TeamMemberSelectListAssignedListItem[]): {
    name: string;
    assignedList: TeamMemberSelectListAssignedListItem[];
}[] => {
    const teams = []

    if (assignedList.some(({ role }) => !isRoleABorrower(role))) {
        teams.push({
            name: 'Lending Team',
            assignedList: assignedList.filter(({ role }) => !isRoleABorrower(role))
        })
    }

    if (assignedList.some(({ role }) => isRoleABorrower(role))) {
        teams.push({
            name: 'Borrowing Team',
            assignedList: assignedList.filter(({ role }) => isRoleABorrower(role))
        })
    }

    return teams
}